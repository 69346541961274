import React, { useEffect } from "react";
import loaderIcon from "../../assets/img/loader-icon.png";
import { seasons } from "../Home/theme";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { IRootState } from "../../redux/reducer";
import {
  getEpisodeDataEmpty,
  getEpisodeDataRequest,
} from "../../redux/actions/movieDetailaction";
import Loader from "../../Component/Loader";
import { ICreateListState } from "../../model/createList";
import { MESSAGE } from "../../utils/constant";
import { PLAYER } from "../../routes/routesConstant";

export const SeriesDetail = (props: any) => {
  const { seriesDetailData, episodeDetailData, episodeExist } = useSelector<
    IRootState,
    any
  >((state) => state.movieDetailReducer);
  const [activeSeason, setActiveSeason] = React.useState(
    seriesDetailData?.[0]?.seasion_id,
  );
  const { createVideoListBool } = useSelector<IRootState, ICreateListState>(
    (state) => state.createListReducer,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const video_type = searchParams.get("video_type") || "";
  const [loader, setLoader] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const dispatch = useDispatch();
  const { loginData } = props;
  const user_id = loginData?.user_id;
  const navigate = useNavigate();
  useEffect(() => {
    if (count)
      dispatch(
        getEpisodeDataRequest({
          video_id: props.video_id,
          seasion_id: activeSeason,
          start_range: count,
          user_id,
        }),
      );
  }, [count]);
  useEffect(() => {
    if (seriesDetailData?.[0]?.season) {
      dispatch(getEpisodeDataEmpty());
      setActiveSeason(seriesDetailData?.[0]?.seasion_id);
      seasons();
    }
    if (!seriesDetailData?.length) {
      dispatch(getEpisodeDataEmpty());
    }
  }, [seriesDetailData]);
  useEffect(() => {
    if (count && !episodeExist?.length) {
      setLoader(false);
    }
  }, [episodeDetailData]);

  useEffect(() => {
    if (activeSeason) {
      dispatch(
        getEpisodeDataRequest({
          video_id: props.video_id,
          seasion_id: activeSeason,
          start_range: count,
          user_id,
        }),
      );
    }
  }, [activeSeason]);
  useEffect(() => {
    if (createVideoListBool) {
      dispatch(
        getEpisodeDataRequest({
          video_id: props.video_id,
          seasion_id: activeSeason,
          start_range: 0,
          user_id,
        }),
      );
      setCount(0);
    }
  }, [props.seriesActive]);
  return (
    <section className="seasons_section">
      <div className="container-fluid">
        <div className="seasons_main">
          <div className="seasonsname_grid">
            <div className="seasonshead_text">
              <span className="seasons_heading">
                <strong>
                  {seriesDetailData?.length > 1 ? "Seasons" : "Season"}
                </strong>
              </span>
            </div>
            <div className="seasonsname_sliderarea">
              <div className="seasonsname_slider owl-carousel">
                {seriesDetailData?.length &&
                  seriesDetailData.map((item: any) => (
                    <div
                      className={`seasonsname_panel ${activeSeason === item.seasion_id ? "current_season" : ""}`}
                      key={item.seasion_id}
                    >
                      <span
                        role="none"
                        onClick={() => {
                          setActiveSeason(item.seasion_id);
                          dispatch(getEpisodeDataEmpty());
                          setLoader(true);
                          setCount(0);
                        }}
                      >
                        {item.season}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {episodeDetailData?.length ? (
            <div className="seasonsepsd_grid">
              <div className="seasons_tab seasons1_tab">
                <div className="row">
                  {episodeDetailData?.length &&
                    episodeDetailData.map((item: any) => (
                      <div
                        className="col-sm-6 col-md-6 col-xl-4"
                        key={item.episode_id}
                      >
                        <div className="seasonsepsd_panel">
                          <div className="seasonsepsd_imgpanel">
                            <img src={item.episode_image} alt="seasons-4" />
                            {item?.play_time ? (
                              <div className="seekbar_panel">
                                <div
                                  className="seekbar_fill"
                                  style={{
                                    width: `${item ? (item?.play_time / item?.total_time) * 100 : 0}%`,
                                  }}
                                ></div>
                              </div>
                            ) : null}
                            <div className="episodeinfo_panel">
                              <span
                                role="none"
                                className="common-btn play_btn"
                                onClick={() => {
                                  if (!loginData?.token) {
                                    props.setLoginAlertBool(
                                      MESSAGE.LOGIN_TO_PLAY_CONTENT,
                                    );
                                    return;
                                  }
                                  navigate(
                                    `${PLAYER}?video_id=${item.video_id}&video_type=${video_type}${item.seasion_id ? "&season_id=" + item.seasion_id : ""}${item.episode_id ? "&episode_id=" + item.episode_id : ""}`,
                                  );
                                }}
                              >
                                <i
                                  className="fa fa-play"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Play"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div className="seasonsepsd_cont">
                            <h3>
                              EP:{item.episode_number} {item.episode_title}
                            </h3>
                            <span className="seasonsepsd_time">
                              {item.episode_duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <Loader type="landscape" />
          )}
        </div>
        {loader ? (
          <div className="lodaer_main">
            <div className="lodaer_grid">
              <span className="leader_icon" onClick={() => setCount(count + 1)}>
                <img src={loaderIcon} alt="loadaer" />
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

