import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { IRootState } from "../../redux/reducer";
import { IHeaderinitialState } from "../../redux/reducer/headerReducer";
import { getMenuRequest } from "../../redux/actions/menuAction";
import list_icon from "../../assets/img/details.png";
import {
  CATEGORY,
  SEARCH,
  SIGN_IN,
  SIGN_UP,
} from "../../routes/routesConstant";
import SearchSavePopup from "../Popup/searchSavePopup";
import {
  deleteSearchItemRequest,
  getSearchQueryRequest,
  resetSaveSearchRequestMessage,
  saveSearchQueryRequest,
} from "../../redux/actions/searchAction";
import { setLeftSideMenuActive } from "../../redux/actions/headerAction";
import { fetchListRequest } from "../../redux/actions/createListAction";

export const LeftMenu = React.forwardRef<HTMLDivElement>((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [savePopupBoolean, setSavePopup] = React.useState(false);
  const { search_query_data, saveSucess, saveFailure, deleteSuccess } =
    useSelector<IRootState, any>((state) => state.searchReducer);

  const { left_active } = useSelector<IRootState, IHeaderinitialState>(
    (state) => state.headerReducer,
  );
  const { dev_base_url, live_base_url } = useSelector<IRootState, any>(
    (state) => state.initialApiReducer,
  );
  const { main_menu, genre, save_searches, era, region, view_result } =
    useSelector<IRootState, any>((state) => state.menuReducer);
  const { loginData } = useSelector<IRootState, any>(
    (state) => state.authReducer,
  );
  const [selectedEra, setSelectedEra] = useState<string[]>([]);
  const [selectedGenre, setSelectedGenre] = useState<string[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
  const [selectedViewType, setSelectedViewType] = useState<string>("");
  useEffect(() => {
    if (dev_base_url || live_base_url) {
      dispatch(getMenuRequest());
      dispatch(getSearchQueryRequest(loginData?.user_id));
    }
    return () => {
      dispatch(resetSaveSearchRequestMessage());
    };
  }, [dev_base_url, live_base_url]);
  const splitArray = (array: [], part: number) => {
    let tmp = [];
    for (let i = 0; i < array.length; i += part) {
      tmp.push(array.slice(i, i + part));
    }
    return tmp;
  };

  const changeGenre = (e: any) => {
    let arr: string[] = selectedGenre;
    let value: string = e.target.value;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSelectedGenre(arr);
  };

  const changeEra = (e: any) => {
    let arr: string[] = selectedEra;
    let value: string = e.target.value;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSelectedEra(arr);
  };

  const changeRegion = (e: any) => {
    let arr: string[] = selectedRegion;
    let value: string = e.target.value;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSelectedRegion(arr);
  };

  const selectViewType = (e: any) => {
    let value: string = e.target.value;
    setSelectedViewType(value);
    // setSelectedRegion(arr);
  };

  const submitSearch = (e: any) => {
    e.preventDefault();
    navigate(
      `${SEARCH}?viewType=${selectedViewType}&era=${selectedEra.join(",")}&genre=${selectedGenre.join(",")}&region=${selectedRegion.join(",")}&showText=0`,
    );
  };

  const saveSearch = (name: string) => {
    dispatch(
      saveSearchQueryRequest({
        user_id: loginData?.user_id,
        genre: selectedGenre.join(","),
        name,
        region_id: selectedRegion.join(","),
        view_type: selectedViewType,
        era: selectedEra.join(","),
      }),
    );
    setSavePopup(false);
  };
  useEffect(() => {
    if (saveFailure || saveSucess) {
      toast(saveFailure || saveSucess);
      dispatch(resetSaveSearchRequestMessage());
      dispatch(getSearchQueryRequest(loginData?.user_id));
    }
  }, [saveFailure, saveSucess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast(deleteSuccess);
      dispatch(resetSaveSearchRequestMessage());
    }
  }, [deleteSuccess]);
  return (
    <>
      {/* <ToastContainer /> */}
      {savePopupBoolean ? (
        <SearchSavePopup setSavePopup={setSavePopup} savePopUp={saveSearch} />
      ) : (
        <></>
      )}
      <nav
        id="Sidenav"
        className={`sidenav ${left_active ? "fixed_sidebar" : ""}`}
      >
        {/* <div className="top-search">
                <Link to={`${SEARCH}?viewType=grid&showText=1`} >
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search" />
                        <span className="input-group-addon close-search"><i className="fa fa-search"></i></span>
                    </div>
                </Link>
            </div> */}
        <div className={`sidebarnav-main`}>
          <div className="navbar-grid">
            <ul className="navbar-group">
              <li className="current" key="search">
                <Link
                  to={`${SEARCH}?viewType=custom_grid&showText=1`}
                  className="yourlst_text"
                >
                  Search
                </Link>
              </li>
            </ul>
            <ul className="navbar-group">
              {main_menu && main_menu.length > 0
                ? main_menu.map((item: any) => {
                    return (
                      <li className="current" key={item.name}>
                        {item.name === "Home" ? (
                          <Link to={`/`} className="yourlst_text">
                            {item.name}
                          </Link>
                        ) : item.name === "Coming Soon" ? (
                          <Link to={`/coming-soon`} className="yourlst_text">
                            {item.name}
                          </Link>
                        ) : (
                          <Link
                            to={`${CATEGORY}?category_id=${item.id}&category_name=${item.name}&type=main_menu`}
                            className="yourlst_text"
                          >
                            {item.name}
                          </Link>
                        )}
                      </li>
                    );
                  })
                : null}
            </ul>

            <ul className="navbar-group">
              {genre && genre.length > 0
                ? genre.map((item: any) => {
                    return (
                      <li className="current" key={item.genre_name}>
                        <Link
                          to={`${CATEGORY}?category_id=${item.id}&category_name=${item.genre_name}&type=genre&genre_type=${item.genre_type}`}
                          className="yourlst_text"
                        >
                          {item.genre_name}
                        </Link>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div className="savesearch_main">
            <div className="savesearch_sidebartext savesearch_guestlogin">
              <strong>Saved Searches</strong>
              {!loginData?.user_id ? (
                <span>
                  *<Link to={`${SIGN_UP}`}>create account</Link> to unlock this
                  feature!{" "}
                </span>
              ) : null}
              <ul className="savesearchaddremove_list">
                {search_query_data?.length &&
                  search_query_data.map((item: any) => (
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      key={item.id}
                    >
                      <span
                        className="savesearch_text"
                        onClick={() =>
                          navigate(
                            `${SEARCH}?searchId=${item.id}&searchName=${item.search_list_name}`,
                          )
                        }
                      >
                        {item?.search_list_name}
                      </span>
                      <span
                        className="remove_savesearch"
                        onClick={() => {
                          dispatch(
                            deleteSearchItemRequest(
                              loginData?.user_id,
                              item.id,
                            ),
                          );
                        }}
                      >
                        X
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="searchfilter-area">
              <div className="advancedsearchsidebr_text" tabIndex={0} ref={ref}>
                <strong> Advanced Search</strong>
                <span>
                  Tired of browsing? Try our advanced tools for finding
                  something good to watch. <br />
                  Option 1 -{" "}
                  <Link className="" to={"/oracle"}>
                    Use The Oracle.
                  </Link>{" "}
                  <br />
                  Option 2 - Refine your search below. Select via Genre, time
                  period (Eras), location (Region), then SUBMIT to look now or
                  SAVE to keep it for later!
                </span>
              </div>
              <div className="filterlist-grid">
                {genre && genre.length > 0 ? (
                  <div className="searchfilter-group">
                    <span className="searchfilter_heading">Genres</span>
                    {splitArray(genre, 2).map((item: any, index: number) => {
                      return (
                        <div className="filter_row filtermobi-list" key={index}>
                          {item &&
                            item.map((i: any) => {
                              return (
                                <div className="filter_col" key={i.id}>
                                  <div className="filter-group">
                                    <div className="form-group">
                                      <div className="form-check">
                                        <div className="custom-control common-checkbox custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={i.genre_name}
                                            value={i.id}
                                            onChange={changeGenre}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={i.genre_name}
                                          >
                                            {i.genre_name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {era && era.length > 0 ? (
                  <div className="searchfilter-group">
                    <span className="searchfilter_heading">Eras</span>
                    {splitArray(era, 2).map((item: any, index: number) => {
                      return (
                        <div className="filter_row filtermobi-list" key={index}>
                          {item &&
                            item.map((i: any) => {
                              return (
                                <div className="filter_col" key={i.id}>
                                  <div className="filter-group">
                                    <div className="form-group">
                                      <div className="form-check">
                                        <div className="custom-control common-checkbox custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={i.era_name}
                                            value={i.id}
                                            onChange={changeEra}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={i.era_name}
                                          >
                                            {i.era_name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {region && region.length > 0 ? (
                  <div className="searchfilter-group">
                    <span className="searchfilter_heading">Region</span>
                    {region.map((item: any) => {
                      return (
                        <div
                          className="filter_row filterregion-list"
                          key={item.id}
                        >
                          <div className="filter_col w-100">
                            <div className="filter-group">
                              <div className="form-group">
                                <div className="form-check">
                                  <div className="custom-control common-checkbox custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={item.region_name}
                                      value={item.id}
                                      onChange={changeRegion}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={item.region_name}
                                    >
                                      {item.region_name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {view_result && view_result.length ? (
                  <div className="searchfilter-group view_filter">
                    <span className="searchfilter_heading">View Results</span>
                    {view_result.map((item: any) => {
                      return (
                        <div className="filter_row" key={item.id}>
                          <div className="filter_col">
                            <div className="filter-list">
                              <div className="filter-group">
                                <div className="form-group">
                                  <div className="custom-control common-radio custom-radio">
                                    <input
                                      type="radio"
                                      id={item.name}
                                      value={item.view_type}
                                      name="customRadio"
                                      className="custom-control-input"
                                      onChange={selectViewType}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={item.name}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item.view_type === "custom_grid" ? (
                            <div className="filter_col">
                              <div className="viewsmall-grid">
                                <span className="viewsmall-card"></span>
                                <span className="viewsmall-card"></span>
                                <span className="viewsmall-card"></span>
                                <span className="viewsmall-card"></span>
                                <span className="viewsmall-card"></span>
                                <span className="viewsmall-card"></span>
                              </div>
                            </div>
                          ) : (
                            <div className="filter_col">
                              <div className="viewdetails-grid">
                                <img src={list_icon} alt="list icon" />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="submitsave_parent">
                  <span className="submitsave_panel">
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={submitSearch}
                    >
                      <span>submit</span>
                    </button>
                  </span>
                  <span
                    className={`submitsave_panel ${!loginData?.user_id ? "submitsave_guestlogin" : ""}`}
                  >
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        dispatch(setLeftSideMenuActive(false));
                        if (!loginData?.user_id) {
                          navigate("/signin");
                        } else {
                          dispatch(resetSaveSearchRequestMessage());
                          setSavePopup(true);
                        }
                      }}
                    >
                      <span>save </span>
                    </button>
                    {!loginData?.user_id ? (
                      <span className="create_guest">
                        *<Link to={`${SIGN_UP}`}>create account</Link>
                        <br />
                        <span>
                          {" "}
                          to unlock <br /> this feature!
                        </span>
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
});

